<template>
  <Modal class="dialog" v-model="pShown" :title="publicityID ? '编辑公示信息' : '发布公示信息'" :width="800">
    <div v-if="!canRelease">
      企业或免费会员才可以发布公示，是否前往个人页进行企业认证？
    </div>
    <template v-else-if="isNotNeedPay === 0">
      <div>
        <div>抱歉，该功能需要单次充值才能继续使用，或者到个人页<span style="color: red">充值会员</span>以享受最高优惠！</div>
        <div class="pay__panel">
          <template v-if="qrCode">
            <div class="qrcode-box" ref="qrcode"></div>
            <div class="right">
              <div class="price">{{price}}</div>
              <img class="pay_logo" :src="payLogo" />
              <div class="tip">使用微信扫码支付，支付完请稍等片刻！</div>
            </div>
          </template>
          <div v-else-if="qrErrMsg">{{qrErrMsg}}</div>
          <template v-else>支付二维码加载中...</template>
        </div>
      </div>
    </template>
    <template v-else-if="hasInit">
      <div class="layout-row">
        <div class="label required">名称：</div>
        <Input v-model="title" type="text" maxlength="200" style="width: 300px;" placeholder="区域（省市县区）+公司名称（个人姓名）"></Input>
      </div>
      <div class="layout-row" style="margin-top: 20px;">
        <div class="label required" style="margin-top: 20px;">详情：</div>
        <vue-tinymce v-model="content" :setup="onSetup" :setting="setting" style="width: 100%;"></vue-tinymce>
      </div>
      <div class="layout-row" v-if="!publicityID">
        <div class="label">模板：</div>
        <RadioGroup v-model="templateId" @on-change="templateChange">
          <Radio v-for="(template, templateIndex) in templates" :key="templateIndex.id" :label="template.id"
            style="margin-right:20px;">{{ template.title }}</Radio>
        </RadioGroup>
      </div>
      <div class="layout-row">
        <div class="label">附件：</div>
        <div class="upload-file__bd-box">
          <draggable class="upload-file__bd" v-model="uploadFiles">
            <div v-for="(item, index) in uploadFiles" :key="index" class="item"
              :style="index%6 == 5 ? 'margin-right:0;' : ''" @click="onViewFile(item)">
              <!-- <img :src="item.src"> -->
              <viewer v-if="item.src !== null" :images="uploadFiles.filter(p => p.src !== null).map(p => p.src)">
                <img :src="item.src">
              </viewer>
              <img v-else :src="nopic">
              <div class="cover">
                <Icon type="ios-trash-outline" @click.native="handleRemove(index)"></Icon>
              </div>
            </div>
          </draggable>
          <!-- <Upload :show-upload-list="false" multiple :before-upload="beforeUpload" action="#" accept="image/png, image/jpeg, image/jpg" style="display: inline-block; margin-top: 10px;"> -->
          <Upload :show-upload-list="false" multiple :before-upload="beforeUploadFiles" action="#"
            accept=".jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf"
            style="display: inline-block; margin-top: 10px;">
            <Button icon="ios-cloud-upload-outline">点击添加</Button>
          </Upload>
        </div>
      </div>
      <!-- <div class="layout-row" style="margin-top: 20px;">
        <div class="label required">负责人：</div>
        <Input v-model="master"></Input>
        <div class="label required">联系电话：</div>
        <Input v-model="masterPhone"></Input>
      </div> -->
      <div class="layout-row" style="margin-top: 20px;">
        <div class="label required">公示单位：</div>
        <Input v-model="company"></Input>
        <div class="label required">联系电话：</div>
        <Input v-model="companyPhone"></Input>
      </div>
      <div class="layout-row">
        <div class="label">详细地址：</div>
        <Input v-model="address" type="text" maxlength="200"></Input>
      </div>
    </template>
    <div v-else style="position: relative; height: 400px;">
      <Spin fix>加载中...</Spin>
    </div>
    <div slot="footer">
      <!-- <Button type="default" :disabled="requesting" @click="pShown = false" size="large">取消</Button> -->
      <Button v-if="hasInit || !canRelease" type="primary" :disabled="requesting" @click="done" size="large">
        {{ !canRelease ? '前往认证' : (publicityID ? '保存' : '提交审核') }}
      </Button>
    </div>
    <PreviewDocx v-if="docxInfo" v-model="docxInfo" />
  </Modal>
</template>

<script>
  // 主题
  import 'tinymce/themes/silver'

  // 插件
  import 'tinymce/plugins/link' // 链接插件
  import 'tinymce/plugins/image' // 图片插件
  import 'tinymce/plugins/media' // 媒体插件
  import 'tinymce/plugins/table' // 表格插件
  import 'tinymce/plugins/lists' // 列表插件
  import 'tinymce/plugins/quickbars' // 快速栏插件
  import 'tinymce/plugins/fullscreen' // 全屏插件
  import 'tinymce/plugins/preview' // 预览插件

  /**
   * 注：
   * 5.3.x版本需要额外引进图标，没有所有按钮就会显示not found
   */
  import 'tinymce/icons/default/icons'

  // 本地化
  import '@/libs/tinymce/langs/zh_CN.js'

  import {
    mapMutations,
    mapGetters
  } from 'vuex'
  import draggable from 'vuedraggable'
  import {
    uploadImage
  } from '@/api/common.js'
  // import { addPublicityEx } from '@/api/publicity.js'
  import {
    addOrEditPublicity
  } from '@/api/publicity.js'
  import {
    getPublicityTemplateList
  } from '@/api/publicity-template.js'
  import {
    getMemberSetting
  } from '@/api/setting.js'
  import QRCode from 'qrcodejs2'
  import {
    checkReleaseIsPaid,
    getReleasePayQr,
    checkTransaction
  } from '@/api/user'
  import PreviewDocx from '@/components/PreviewDocx'

  export default {
    name: 'PublicityEditor',
    components: {
      draggable,
      PreviewDocx
    },
    model: {
      prop: 'shown',
      event: 'change'
    },
    props: {
      shown: {
        type: Boolean,
        default: false
      },
      info: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        nopic: require('@/assets/images/nopic.png'),
        pShown: false,
        hasInit: false,
        requesting: false,

        canRelease: false,
        /* 支付相关字段 */
        isNotNeedPay: -1,
        price: 0,
        payLogo: require('@/assets/images/wechat_pay.png'),
        qrCode: null,
        tradeNo: null,
        qrErrMsg: null,
        timers: null,

        title: null,
        master: null,
        masterPhone: null,
        company: null,
        companyPhone: null,
        address: null,

        uploadFiles: [],

        content: '',

        setting: {
          doctype: '<!DOCTYPE html>',
          menubar: false,
          toolbar: 'undo redo | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | preview fullscreen |  fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
          quickbars_selection_toolbar: 'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
          plugins: 'link image media table lists preview fullscreen quickbars',
          language: 'zh_CN',
          branding: false, // 隐藏右下角技术支持，这个可以自行切换true/false看看到底是什么
          draggable_modal: false, // 模态框可拖动，这里的模态框指的是点击工具栏里面
          elementpath: false, // 隐藏元素路径，
          // max_height: 448,
          // min_height: 448,
          height: '300',
          document_base_url: this.$config.baseUrl(),
          relative_urls: true,

          content_style: `
          img                       { display:block; max-width:100%;height:auto; }'`,

          // 自定义上传图片
          async images_upload_handler(blobInfo, success, failure) {
            try {
              const res = await uploadImage(blobInfo.blob(), {
                path: 'publicity',
                compress: 'n'
              })
              success(res.content.src)
            } catch (err) {
              failure(err.message)
            }
          }
        },

        templates: [],
        templateId: null,

        docxInfo: null
      }
    },
    computed: {
      ...mapGetters(['userInfo', 'hasLogin']),
      publicityID() {
        if (this.info) {
          return this.info.id
        }
        return null
      }
    },
    watch: {
      pShown: async function(newVal) {
        this.$emit('change', newVal)
        this.canRelease = this.userInfo.is_free == 1 || this.userInfo.is_company_authentication == 1
        if (newVal) {
          // 如果该用户发布需要收费，则检查是否已付费，否则弹出收费窗口
          let res = await getMemberSetting()
          switch (this.userInfo.vip_info.level) {
            case 0:
              this.price = parseInt(res.content.free.fee.release_publicity)
              break
            case 1:
              this.price = parseInt(res.content.level1.fee.release_publicity)
              break
            case 2:
              this.price = parseInt(res.content.level2.fee.release_publicity)
              break
          }
          if (this.price > 0) {
            res = await checkReleaseIsPaid({
              release_type: 'publicity'
            })
            this.isNotNeedPay = parseInt(res.content)
          } else {
            this.isNotNeedPay = 1
          }
          // console.log('价格: ' + this.price, '需要支付: ' + this.isNotNeedPay === 0 ? '是' : '否')

          if (this.isNotNeedPay === 0 && !this.info) { // 需要付费且为新增
            // 获取收费二维码
            this.requestPayQr()
          } else {
            this.isNotNeedPay = 1

            if (this.info) {}

            await this.getPublicityTemplateList()
            this.hasInit = true
          }
        } else {
          setTimeout(() => {
            Object.assign(this.$data, this.$options.data.call(this))
          }, 300)
        }
      },
      shown: function(newVal) {
        this.pShown = newVal
      },
      qrCode(newVal) {
        if (this.$refs.qrcode) {
          this.$refs.qrcode.innerHTML = ''
        }
        if (newVal != null) {
          this.$nextTick(() => {
            this.showQRcode()
          })
        }
      }
    },
    methods: {
      /* 支付 开始 */
      showQRcode() {
        const _ = new QRCode(this.$refs.qrcode, {
          text: this.qrCode, // 需要转换为二维码的内容
          width: 160,
          height: 160,
          colorDark: '#000',
          colorLight: '#fff',
          correctLevel: QRCode.CorrectLevel.H
        })

        if (this.timers) {
          clearInterval(this.timers)
        }

        this.timers = setInterval(async () => {
          await this.getOrderState()
        }, 3000)
      },
      async requestPayQr() {
        this.qrErrMsg = null
        try {
          const params = {
            release_type: 'publicity'
          }
          const res = await getReleasePayQr(params)
          this.tradeNo = res.content.trade_no
          this.qrCode = res.content.code_url
        } catch (error) {
          this.$Message.error(error.message)
          this.qrCode = null
          this.qrErrMsg = error.message
        }
      },
      async getOrderState() {
        if (this.tradeNo == null) return
        try {
          const params = {
            trade_no: this.tradeNo
          }
          await checkTransaction(params)
          this.$Message.success('支付成功')
          if (this.timers) {
            clearInterval(this.timers)
          }
          this.isNotNeedPay = 1
          await this.getPublicityTemplateList()
          this.hasInit = true
        } catch (error) {}
      },
      /* 支付 结束 */
      async getPublicityTemplateList() {
        await getPublicityTemplateList({
          isGetAll: 1
        }).then(response => {
          this.templates = response.content.rows
        })
      },
      templateChange(e) {
        console.log(e)
        const index = this.templates.findIndex(item => item.id === e)
        if (index !== -1) {
          this.content = this.templates[index].detail
        }
      },
      onSetup(editor) {},
      async done() {
        if (!this.canRelease) {
          this.$router.push({
            path: '/user',
            query: {
              isOpenEditInfo: 1,
              tabName: '4'
            }
          })
          return
        }
        if (this.title == null || this.title.length == 0) {
          this.$Message.error('请输入项目名称')
          return
        }

        if (this.content == null || this.content.length == 0) {
          this.$Message.error('请输入项目详情')
          return
        }

        /* if (this.master == null || this.master.length == 0) {
          this.$Message.error('请输入负责人')
          return
        }
        if (this.masterPhone == null || this.masterPhone.length == 0) {
          this.$Message.error('请输入负责人联系电话')
          return
        } */
        if (this.company == null || this.company.length == 0) {
          this.$Message.error('请输入公示单位')
          return
        }
        if (this.companyPhone == null || this.companyPhone.length == 0) {
          this.$Message.error('请输入公示单位联系电话')
          return
        }
        const len = this.uploadFiles ? this.uploadFiles.length : 0
        if (len < 1) {
          this.$Message.error('请添加附件')
          return
        }

        this.$Spin.show()

        // 总是添加 width: 100%; height: auto; 样式
        let content = this.content.replace(/<img src="/gi, '<img style="width: 100%; height: auto;" src="')
        content = content.replace(/<img style="(?!width:)/gi, '<img style="width: 100%; height: auto; ')

        const params = {
          title: this.title,
          detail: content,
          master: this.master,
          master_phone: this.masterPhone,
          company: this.company,
          company_phone: this.companyPhone,
          address: this.address
        }

        if (this.publicityID) {
          params.id = this.publicityID
        }

        const uploadImages = []
        const oldImages = []
        const uploadOrders = []
        const oldImagesOrders = []

        for (let i = 0; i < len; i++) {
          if (this.uploadFiles[i].id == null) {
            uploadImages.push(this.uploadFiles[i])
            uploadOrders.push(i)
          } else {
            oldImages.push(this.uploadFiles[i].id)
            oldImagesOrders.push(i)
          }
        }

        const fileDes = {
          name: 'image[]',
          file: uploadImages
        }

        params.upload_orders = uploadOrders.join(',')
        params.old_images = oldImages.join(',')
        params.old_image_orders = oldImagesOrders.join(',')

        this.requesting = true

        try {
          const res = await addOrEditPublicity(fileDes, params)
          const detail = {
            info: res.content
          }
          if (this.publicityID) {
            detail.isEdit = true
            detail.index = this.info.index
            this.$Message.success('保存成功')
          } else {
            this.$Message.success('发布成功')
          }
          this.$emit('on-success', detail)

          this.$Spin.hide()
          this.pShown = false
        } catch (err) {
          this.$Spin.hide()
          this.$Message.error(err.message)
        }

        this.requesting = false
      },
      // beforeUpload(file) {
      //   const check = this.uploadFiles.length < 1
      //   if (!check) {
      //     this.$Message.error('最多上传 1 张图片')
      //     return false
      //   }

      //   const reader = new FileReader()
      //   reader.readAsDataURL(file)

      //   reader.onloadend = (e) => {
      //     file.src = reader.result
      //     this.uploadFiles.push(file)
      //   }
      //   return false
      // },
      beforeUploadFiles(file) {
        console.log(file)
        const check = this.uploadFiles.length < 100
        if (!check) {
          this.$Message.error('最多上传 100 个附件')
          return false
        }

        if (/image\/\w+/.test(file.type)) { // 图片，显示预览
          const reader = new FileReader()
          reader.readAsDataURL(file)

          reader.onloadend = (e) => {
            file.src = reader.result
            this.uploadFiles.push(file)
          }
        } else {
          file.src = null
          this.uploadFiles.push(file)
        }
        return false
      },
      handleRemove(index) {
        this.uploadFiles.splice(index, 1)
      },
      onViewFile(file) {
        // console.log(file)
        // if (file) {
        //   this.docxInfo = { file: file }
        // }
      }
    }
  }
</script>

<style lang="less" scoped>
  // 样式
  @import '~tinymce/skins/ui/oxide/skin.min.css';
  @import '~tinymce/skins/ui/oxide/content.min.css';

  .dialog {
    ::v-deep .ivu-modal-body {
      padding: 16px;
      min-height: 436px;
    }
  }

  .label {
    margin-bottom: 5px;
  
  &.required::before {
      content: '*';
      display: inline-block;
      margin-right: 4px;
      line-height: 1;
      font-family: SimSun;
      font-size: 14px;
      color: #ed4014;
    }
  }

  .layout-row {
    margin-bottom: 15px;

    .label {
      width: 100px;
      text-align: right;
      margin-right: 5px;
      flex: 0 0 auto;

      &.required::before {
        content: '*';
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
      }
    }
  }

  .upload-file__bd-box {
    display: inline-block;
    min-width: 132px;
  }

  .upload-file__bd {
    position: relative;
    box-sizing: border-box;
    min-height: 160px;
    margin-top: 10px;
    padding: 10px 10px 0;
    border: solid 1px #dbdde1;
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;

    .item {
      position: relative;
      width: 100px;
      height: 140px;
      margin-bottom: 10px;
      margin-right: 8px;

      img {
        position: absolute;
        width: 100px;
        height: 100px;
        // width: 100%;
        // height: 100%;
        object-fit: cover;
      }

      .cover {
        display: none;
        position: absolute;
        width: 100px;
        height: 100px;
        // width: 100%;
        // height: 100%;
        background: rgba(0, 0, 0, 0.3);
        pointer-events: none;

        i {
          color: #fff;
          font-size: 20px;
          cursor: pointer;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -60%);
          pointer-events: auto;
        }
      }

      .file-name {
        position: absolute;
        bottom: 0;
        height: 40px;
        line-height: 20px;
        background-color: #fff;

        overflow: hidden;
        word-break: break-all;
        /* break-all(允许在单词内换行。) */
        text-overflow: ellipsis;
        /* 超出部分省略号 */
        display: -webkit-box;
        /** 对象作为伸缩盒子模型显示 **/
        -webkit-box-orient: vertical;
        /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        -webkit-line-clamp: 2;
        /** 显示的行数 **/
      }
    }

    .item:hover {
      .cover {
        display: block;
      }
    }
  }

  .pay__panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;

    .right {
      display: flex;
      height: 160px;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;

      .price {
        color: #ff3a3a;
        text-align: center;
        font-size: 40px;
        line-height: 60px;
        font-weight: 500;

        &::after {
          content: " 元";
          font-size: 14px;
        }
      }

      .pay_logo {
        flex-shrink: 0;
        width: 120px;
        height: 40px;
        object-fit: contain;
      }

      .tip {
        line-height: 40px;
        font-size: 15px;
      }
    }

    .err {
      text-align: center;
    }
  }

  .qrcode-box {
    display: inline-block;
    width: 160px;
    height: 160px;
  }
</style>
