<template>
  <!-- 根据url获取blob数据，通用版，适配：word、excel、ppt、pdf -->
  <Modal class="dialog" v-model="pShown" :mask-closable="false" :title="title" :styles="{top: '20px'}" width="900">
    <div v-if="docxData" ref="file" key="doc"></div>
    <div v-else key="loading" style="position: relative; height:400px;">
      <Spin fix>加载中...</Spin>
    </div>
    <div slot="footer">
      <Button type="primary" @click="pShown = false">确 定</Button>
    </div>
  </Modal>
</template>

<script>
// https://www.npmjs.com/package/docx-preview
// https://github.com/zVolodymyr/docxjs
// import docx from 'docx-preview'
import { getBlob } from '@/api/common.js'

const docx = require('docx-preview')

export default {
  name: 'PreviewDocx',
  model: {
    prop: 'info',
    event: 'change'
  },
  props: {
    info: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      pShown: false,
      docxData: null
    }
  },
  computed: {
    title() {
      if (this.info) {
        return this.info.detail.name
      }
      return ''
    }
  },
  watch: {
    pShown(newVal) {
      if (newVal) {
        this.initData()
      } else {
        this.$emit('change', null)
        setTimeout(() => {
          this.docxData = null
        }, 300)
      }
    },
    info(newVal) {
      if (newVal) {
        this.pShown = true
      }
    }
  },
  methods: {
    async initData() {
      const docxOptions = Object.assign(docx.defaultOptions, {
        debug: false,
        inWrapper: true,
        experimental: true,
        renderHeaders: true,
        renderFooters: true,
        renderFootnotes: true
      })
      try {
        let res = null
        if (this.info.detail.save_path) {
          res = await getBlob({ save_path: this.info.detail.save_path })
          if (res) {
            this.docxData = res.data
            this.$nextTick(() => {
              // 渲染到页面
              docx.renderAsync(this.docxData, this.$refs.file, null, docxOptions)
            })
          }
        } else if (this.info.file) { // TODO：file类型
          // this.$nextTick(() => {
          //   // 渲染到页面
          //   docx.renderAsync(this.docxData, this.$refs.file, null, docxOptions)
          // })
        }
      } catch (err) {
        this.$Message.error(err.message)
      }
    }
  }
}

</script>

<style lang="less" scoped>
.dialog {
  ::v-deep .ivu-modal-body {
    position: relative;
    min-height: 421px;
    max-height: 650px;
    overflow: hidden scroll;
    padding-top: 5px;
  }
}
</style>
