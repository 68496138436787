import axios from './api.request'

/**
 * 获取公示列表
 */
export const getPublicityIndex = (params) => {
  return axios.request({
    url: 'Publicity/index',
    params,
    method: 'get'
  })
}

/**
 * 发布公示、编辑已发布的公示
 */
export const addOrEditPublicity = (fileDes, params) => {
  return axios.uploadFile('Publicity/add', fileDes, params)
}

/**
 * 发布公示、编辑已发布的公示（富文本版本）
 */
export const addPublicityEx = (params) => {
  return axios.request({
    url: 'Publicity/addEx',
    data: params,
    method: 'post'
  })
}

export const uploadAttachment = (fileDes, params) => {
  return axios.uploadFile('Publicity/uploadAttachment', fileDes, params)
}

/**
 * 获取公示列表
 */
export const getPublicityList = (params) => {
  return axios.request({
    url: 'Publicity/publicityList',
    params,
    method: 'get'
  })
}

/**
 * 删除公示
 */
export const deletePublicity = (id) => {
  return axios.request({
    url: 'Publicity/delete',
    data: {id},
    method: 'post'
  })
}

/**
 * 获取公示详情
 */
export const getPublicityDetail = (id) => {
  return axios.request({
    url: 'Publicity/detail',
    params: {id},
    method: 'get'
  })
}
