<template>
  <Modal class="dialog" v-model="pShown" :title="title" :mask-closable="false" width="680" footer-hide>
    <div class="section-row">
      <div>公示单位：{{company}}</div>
      <div>联系电话：{{companyPhone}}</div>
    </div>
    <div class="section-row">
      <div>项目负责人：{{master}}</div>
      <div>联系电话：{{masterPhone}}</div>
    </div>
    <iframe id="preview_frame"></iframe>
  </Modal>
</template>

<script>

import { getPublicityDetail } from '@/api/publicity.js'

export default {
  name: 'PublicityDetail',
  model: {
    prop: 'info',
    event: 'change'
  },
  props: {
    info: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      pShown: false,

      master: null,
      masterPhone: null,
      company: null,
      companyPhone: null,
      detail: ''
    }
  },
  computed: {
    title() {
      if (this.info) {
        return this.info.title
      }
      return null
    }
  },
  watch: {
    pShown(newVal) {
      if (newVal) {
        this.initData()
      } else {
        setTimeout(() => {
          this.$emit('change', null)
          Object.assign(this.$data, this.$options.data.call(this))
          if (this.htmlDoc) {
            this.htmlDoc.body.innerHTML = null
          }
        }, 300)
      }
    },
    info(newVal) {
      if (newVal) {
        this.pShown = true
      }
    }
  },
  mounted() {
    // 设置预览的 iframe
    const doc =
        document.getElementById('preview_frame').contentDocument ||
        document.frames.preview_frame.document

    doc.body.style = 'margin:20px;'

    // 设置基地址
    const base = document.createElement('base')
    base.href = this.$config.baseUrl()
    doc.head.appendChild(base)

    this.htmlBase = base
    this.htmlDoc = doc
  },
  methods: {
    async initData() {
      try {
        const res = await getPublicityDetail(this.info.id)
        const content = res.content.content
        this.master = content.master
        this.masterPhone = content.master_phone
        this.company = content.company
        this.companyPhone = content.company_phone
        if (res.content.base_url) {
          this.htmlBase.href = res.content.base_url
        }
        this.htmlDoc.body.innerHTML = content.detail
        this.detail = content.detail
      } catch (err) {
        this.$Message.error(err.message)
      }
    }
  }
}

</script>

<style lang="less" scoped>
.dialog {
  ::v-deep .ivu-modal-body {
    padding: 16px;
    min-height: 600px;
  }
}

.section-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #eee;
}

iframe {
  height: 420px;
  width: 100%;
  border: none;
}
</style>
