<template>
  <div class="container">
    <template v-if="hasLogin">
      <Button type="error" ghost class="post-btn" @click="onPost">发布公示</Button>
      <div class="filter-box">
        <Input class="search-input" v-model="table.search" placeholder="请输入搜索内容" style="width: 500px" size="large" maxlength="30" :clearable="true" @on-change="onSearchChange" search @keyup.enter.native="submitSearch"></Input>
        <Button class="search-btn" type="primary" icon="ios-search" size="large" @click="submitSearch">搜索</Button>
      </div>
      <!-- 内容 -->
      <div class="list__panel">
        <template v-if="listData.length > 0">
          <a v-for="item in listData" :key="item.id" class="list-item"  @click="onViewPublicity(item)">
            <viewer v-if="item.avatar_url !== null && item.avatar_url !== ''">
              <img class="img" :src="item.creater_avatar" @error="item.image = nopic"/>
            </viewer>
            <Avatar v-else shape="square" icon="ios-person" :size="60" />
            <div class="info-panel">
              <div class="title">{{item.title}}</div>
              <div class="bottom">
                <div class="create">公示时间：<span>{{item.create_date}}</span></div>
                <div>
                  <span>公示单位：</span><span class="contact">{{item.company}}</span>
                  <span style="margin-left: 20px;">联系电话：</span><span class="contact">{{item.company_phone}}</span>
                </div>
              </div>
            </div>
          </a>
        </template>
        <div v-else class="nodata-tip">暂无数据</div>
      </div>
      <PublicityEditor v-model="showAddPublicity" @on-success="onAddPublicitySuccess"/>
      <PublicityDetail v-model="publicityInfo" />
    </template>
    <div v-else class="content__err">
      <a @click="onLogin">请先登录</a>
    </div>
  </div>
</template>

<script>
import PublicityEditor from '@/components/PublicityEditor.vue'
import PublicityDetail from '@/components/PublicityDetail.vue'
import { mapMutations, mapGetters } from 'vuex'
import { getPublicityList } from '@/api/publicity.js'

export default {
  name: 'Publicity',
  components: {
    PublicityEditor,
    PublicityDetail
  },
  data() {
    return {
      // 列表数据
      currentPage: 1,
      pageSize: 20,
      totalCount: 0,
      listData: [],
      table: {
        search: null
      },

      showAddPublicity: false,
      publicityInfo: null
    }
  },
  computed: {
    ...mapGetters(['hasLogin', 'cityId'])
  },
  watch: {
    hasLogin(newVal) {
      if (newVal) {
        this.getPublicityListData()
      } else {
        Object.assign(this.$data, this.$options.data.call(this))
      }
    }
  },
  mounted() {
    this.getPublicityListData()
  },
  methods: {
    ...mapMutations(['showLoginDialog']),
    onLogin() {
      this.showLoginDialog()
    },
    // 搜索内容改变
    async onSearchChange(e) {
      if (this.table.search == '') { // 清空内容时刷新列表
        await this.submitSearch()
      }
    },
    async submitSearch() {
      this.currentPage = 1
      await this.getPublicityListData()
    },
    async getPublicityListData(start = null) {
      try {
        if (start == null) {
          start = (this.currentPage - 1) * this.pageSize
        }
        const params = {start, count: this.pageSize, search: this.table.search, city_id: this.cityId}
        console.log(params)
        const res = await getPublicityList(params)
        this.totalCount = parseInt(res.content.total_count)
        this.listData = res.content.rows
      } catch (error) {
        this.$Message.error(error.message)
      }
    },
    onPost() {
      this.showAddPublicity = true
    },
    onAddPublicitySuccess() {
      // this.getPublicityListData(0)
      this.$router.push({ path: '/user', query: { menuActive: '4' } })
    },
    onViewPublicity(item) {
      // this.publicityInfo = {id: item.id, title: item.title}
      const routeData = this.$router.resolve({ name: 'PublicityDetail', query: {id: item.id} })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 20px 0 40px;
}

.content__err {
  padding: 40px 0;
  width: fit-content;
  margin: 0 auto;
}

.filter-box {
  text-align: center;
  padding: 20px 0;

  .search-input {

  }
  .search-btn {
    margin-left: 10px;
  }
}

.list__panel {
  margin-top: 50px;
}

.post-btn {
  float: right;
  // color: #ff552e;
}

.list-item {
  display: flex;
  height: 100px;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  border-top: solid 1px #dbdde1;

  .img {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    object-fit: cover;
    // margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #f0f0f0;
  }
  .info-panel {
    margin-left: 20px;
    flex: 1;
    overflow: hidden;
    .title {
      color: #000;
      font-size: 18px;
      height: 25px;
      line-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .detail {
      margin-top: 5px;
      color: #666;
      font-size: 15px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .bottom {
      display: flex;
      margin-top: 10px;
      height: 20px;
      justify-content: space-between;
      color: #666;
      .create {
        color: #999;
      }
      .contact {
        color: #2e86b9
      }
    }
  }
}

</style>
