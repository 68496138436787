import axios from './api.request'

/**
 * 获取公式模板列表
 */
export const getPublicityTemplateList = (params) => {
  return axios.request({
    url: 'PublicityTemplate/publicityTemplateList',
    params,
    method: 'get'
  })
}
